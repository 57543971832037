<template>
  <div class="wrapper" :class="{ 'has-background': hasBackground }">
    <SearchInput @click="goToSearch" />
    <button @click="goToSearch">
      <img src="@/assets/icons/filter.svg" alt="filter">
    </button>
    <button @click="goToAboutUs" class="ml-a">
      <img src="@/assets/icons/white-info.svg" alt="info" />
    </button>
    <button @click="goToCart">
      <img src="@/assets/icons/basket.svg" alt="basket">
      <span class="amount" v-if="totalItemAmount > 0">
        {{ totalItemAmount }}
      </span>
    </button>
    <LanguageSwitcher />
  </div>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

import SearchInput from '@/components/SearchInput'
import LanguageSwitcher from './LanguageSwitcher'

export default {
  props: {
    hasBackground: {
      type: Boolean,
      default: false
    },
  },
  components: {
    SearchInput,
    LanguageSwitcher
  },
  setup() {
    /** context */
    const store = useStore()
    const router = useRouter()

    /** computed */
    const totalItemAmount = computed(() => store.getters['cart/totalItemAmount'])

    /** method */
    const goToCart = () => router.push('/my-cart')
    const goToSearch = () => {
      store.commit('filter/RESET_ALL')
      router.push('/products')
    }
    const goToAboutUs = () => {
      router.push('/about-us')
    }
    
    return {
      totalItemAmount,
      goToCart,
      goToSearch,
      goToAboutUs,
    }
  }
}
</script>

<style lang="scss" scoped>

.wrapper {
  padding: 16px 8px;
  display: flex;
  z-index: 8;
  > * {
    margin: 0 8px;
  }
  &.has-background {
    background: var(--secondary-color);
    margin: 0;
  }
}

button {
  border: 0;
  outline: none;
  background-color: transparent;
  position: relative;
  &:active {
    opacity: 0.8;
  }
}

.amount {
  position: absolute;
  border-radius: 50%;
  top: 0;
  right: 0;
  transform: translate(50%, 25%);
  width: 18px;
  height: 18px;
  background-color: var(--error-color);
  color: var(--white-color);
}
</style>
