<template>
  <div class="login-wrapper">
    <p class="back" @click="back">&lt; {{ t('back') }}</p>
    <img src="@/assets/icons/m-coin.svg" alt="logo" class="logo">
    <br/>
    <div v-if="isSuccess">
     
    <p class="welcome">คุณได้รับ Coin เพิ่ม</p>
    <br/>
    <p class="welcome">{{amoutCoin}} เหรียญ</p>
    </div>
    <div v-else>
      <p class="welcome">กรุณาสแกน QR Code เพื่อรับ Coin</p>
      </div>
    <div class="welcome mt-a"></div>

    <button class="button-wrapper" @click="back">
      ไปที่หน้าหลัก
    </button>
    <br/>
    <!-- <MButton blue @click="goToRegister" >
      สร้างประวัติคนไข้ใหม่
    </MButton> -->
  </div>
</template>

<script>
import {computed, onMounted, ref} from 'vue'
import {useStore} from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import {useI18n} from 'vue-i18n'
import numberWithCommas from '@/utils/numberWithCommas'
import referralApi from '@/api/referral';
import userApi from '@/api/user'

export default {
  name: 'ReferralView',
  components: {
  },
  setup() {
    /** context */
    const store = useStore()
    const refCode = ref('')
    const route = useRoute()
    let refCount = ref(0)
    let amoutCoin = ref(0)
    let isSuccess = ref(false)
    let referralHistory = ref([])
    const v = ref(route.params.v)
    const router = useRouter()
    const {t} = useI18n()

    /** computed */
    const user = computed(() => store.getters['user/getUserProfile'])

    

    /** method */
    const back = () => router.replace('/my-profile')
    const goToMain = () => router.push('/')

    const redirectToLogin = () => {
      localStorage.setItem('redirect', '/referral')
      router.replace('/user/login')
    }

    onMounted(async () => {
      try {
        await store.dispatch('user/fetchGetUserProfile')
        if (!store.getters['user/getUserProfile']) {
          redirectToLogin();
        }else{
          submitCode();
        }
        
      } catch (e) {
        console.error("Error fetching user data: ", e);
        redirectToLogin();
      }
    })


    const submitCode = async () => {

  try {
    
    const rewards = await referralApi.getUserRewards("qr"+v.value);
    const checkQRCode = await userApi.checkQr(v.value);
    let amount = 0;

    try {
      amount = parseInt(checkQRCode.amount) / 40;
    } catch (error) {
      amount = 0;
    }
    amoutCoin.value = amount.toString();
    console.log(amount)
    const rewardData = {
    userId: user.value.id,
    rewardId: 1, 
    dateClaim: new Date().toISOString().split('T')[0],
    type: 'QR_CODE',
    referrerId: "qr"+v.value,
    order_id: amount,
    status: 'CLAIMED'};
    if(rewards.length == 0 && amount != 0){

      const addCoin = await userApi.addCoinRefferal(user.value.id, amount.toFixed(2));
      if(addCoin){
        const result = await referralApi.postUserReward(rewardData);
        if(result){
          isSuccess.value = true; 
        }
      }else{
        alert('ไม่สำเร็จ เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง');
      }

    }else{
      alert('ไม่สำเร็จ รหัสถูกใช้ไปแล้ว กรุณาลองใหม่อีกครั้ง');
    }
  } catch (error) {
    console.error('Error posting referral:', error);
  }
};


    const copyUserIdToClipboard = async () => {
      try {
        await navigator.clipboard.writeText(user.value.id)
      } catch (err) {
        console.error('Failed to copy: ', err)
      }
    }

    return {
      t,
      back,
      user,
      refCode,
      goToMain,
      copyUserIdToClipboard,
      numberWithCommas,
      submitCode,
      refCount,
      isSuccess,
      amoutCoin,
      referralHistory
    }
  }
}

</script>

<style lang="scss" scoped>

.login-wrapper {
  background-color: var(--background-color);
  background-image: url('@/assets/images/bg-logo.png');
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 24px 28px 60px;
}

button {
  width: 100%;
}

.other-link {
  text-decoration: underline;
  cursor: pointer;
  color: var(--secondary-color);
  text-align: center;
  margin-top: 12px;
  &:first-child {
    margin-top: 24px;
  }
}

.logo {
  margin: 0 auto;
  width: 150px;
}

.button-wrapper {
  height: 50px;
  border-radius: 6px;
  border-color: transparent;
  background-color: var(--secondary-color);
  margin-top: 24px;
  color: white;
}

.line-logo {
  margin-right: 12px;
}

.welcome {
  text-align: center;
  font-weight: bold;
  font-size: 50px;
}

.describe {
  font-size: 18px;
  text-align: center;
}

.back {
  color: var(--secondary-color);
  margin-bottom: 12px;
  font-size: 1.2rem;
  padding: 12px;
  cursor: pointer;
}

</style>