<template>
  <div class="login-wrapper">
    <p class="back" @click="backToLogin" v-if="step === 1">&lt; {{ t('back') }}</p>
    <p class="back" @click="back" v-else-if="step === 2">&lt; {{ t('back') }}</p>
    <p class="title">{{ t('loginWithPhone.title') }}</p>
    <div class="log-in-with-phone" v-if="step === 1">
      <p class="description">
        {{ t('loginWithPhone.pleaseFillPhone') }}<br/>
        {{ t('loginWithPhone.forVerify') }}
      </p>
      <TextInput v-model="phone" :label="t('phone')" />
      <DropdownInput
        :label="t('branch')"
        v-model="selectedBranch"
        name="branch"
        :items="branches"
      />
      <MButton @click="getOTP">{{ t('getOTP') }}</MButton>
    </div>
    <div class="input-otp" v-else-if="step === 2">
      <p class="description">
        {{ t('loginWithPhone.pleaseFillOtp') }}
      </p>
      <TextInput autofocus v-model="otp" :label="t('otp')" />
      <MButton @click="verifyOTP">{{ t('confirm') }}</MButton>
      <p v-if="second > 0" class="didnt-get-otp mt-2">{{  t('didntGetOtp') }} ({{ t('otpCounter', { second }) }})</p>
      <p @click="getOTP" class="resend-otp mt-2" v-if="second === 0">{{  t('resendOtp') }}</p>
    </div>
  </div>
</template>

<script>
import { ref, computed, watchEffect, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'

import MButton from '@/components/Form/MButton'
import TextInput from '@/components/Form/TextInput'
import DropdownInput from '@/components/Form/DropdownInput'

export default {
  name: 'RegisterUser',
  components: {
    MButton,
    TextInput,
    DropdownInput,
  },
  setup() {
    /** context */
    const store = useStore()
    const router = useRouter()
    const { t } = useI18n()

    /** reactive */
    const phone = ref('')
    const otp = ref('')
    const step = ref(1)
    const selectedBranch = ref({})
    const second = ref(0)
    const counter = ref(null)
    /** computed */
    const requestIdOtp = computed(() => store.state.user.requestIdOtp)
    const getUser = computed(() => store.getters['user/getUser'])
    const branches = computed(() => store.getters['user/getBranchOptions'])

    /** method */
    const getOTP = () => {
      store.dispatch('user/fetchGetOTP', { phone: phone.value, branchId: selectedBranch.value.value })
    }
    const verifyOTP = () => {
      store.dispatch('user/fetchVerifyOTP', otp.value)
    }
    const back = () => {
      store.commit('user/RESET_REQUEST_ID_OTP')
    }
    const backToLogin = () => {
      router.replace('/user/login')
    }

    onMounted(() => {
      store.dispatch('user/fetchGetBranches')
      store.commit('user/RESET_REQUEST_ID_OTP')
    })
    
    /** watch */
    watchEffect(() => {
      if (requestIdOtp.value) {
        step.value = 2
        second.value = 60
      }
      else {
        second.value = 0
        step.value = 1
      }
    })
    watchEffect(() => {
      if (getUser.value) {
        store.dispatch('user/fetchGetUserProfile', '/my-profile')
      }
    })
    watchEffect(() => {
      if (second.value === 60) {
        counter.value = setInterval(() => {
          second.value--
        }, 1000)
      }
      if (second.value === 0 && counter.value) {
        clearInterval(counter.value)
      }
    })

    return {
      t,
      getOTP,
      verifyOTP,
      phone,
      otp,
      requestIdOtp,
      step,
      back,
      backToLogin,
      branches,
      selectedBranch,
      second,
    }
  }
}
</script>

<style lang="scss" scoped>

.login-wrapper {
  background-color: var(--background-color);
  background-image: url('@/assets/images/bg-logo.png');
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 12px 12px 28px;
}

.log-in-with-phone {
  padding: 0 40px;
}

button {
  width: 100%;
}

.back {
  color: var(--secondary-color);
  margin-bottom: 12px;
  font-size: 1.2rem;
  padding: 12px;
  cursor: pointer;
}

.description {
  color: var(--secondary-color);
  text-align: center;
  font-size: 1.6rem;
  margin-bottom: 24px;
}

.title {
  text-align: center;
  font-size: 1.6rem;
  margin-bottom: 24px;
  color: var(--secondary-color);
}

.didnt-get-otp {
  color: var(--secondary-color);
}
.resend-otp {
  color: var(--secondary-color);
  text-decoration: underline;
  cursor: pointer;
}
</style>
